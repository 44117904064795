<template>
  <div class="selected-tag">
    <span style="margin-right: 35px; margin-left: 10px;">
      <slot name="tag-label"></slot>
    </span>
    <i aria-hidden="true"
      tabindex="1"
      @click.self="removeButtonClicked()"
      class="selected-tag__icon"></i>
  </div>
</template>
<script>
export default {
  props: [
    'itemID'
  ],
  methods: {
    removeButtonClicked () {
      this.$emit('remove-clicked', this.itemID)
    }
  }
}
</script>
