<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      services: [],
      service: {},
      statusCode: null
    }
  },

  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage', 'appendDangerMessage']),
    async getAllServices () {
      await axios
        .get(`${BeaconapiUrl}/api/service`)
        .then((result) => {
          this.services = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getAllServicesByProgramId (programId) {
      await axios
        .get(`${BeaconapiUrl}/api/service/by-program/${programId}`)
        .then((result) => {
          this.services = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getServiceById (serviceID) {
      return await axios
        .get(`${BeaconapiUrl}/api/service/${serviceID}/false`)
        .then((result) => { this.service = result.data })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async updateService (service) {
      await axios
        .put(`${BeaconapiUrl}/api/service`, service)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(`Service ${result.data.serviceInitials} Successfully Updated!`)
        })
        .catch(error => {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            this.setDangerMessage(error.message)
          } else if (error.request) {
            // client never received a response, or request never left
            this.setDangerMessage(error.message)
          } else {
            // anything else
            this.setDangerMessage(error.message)
          }
        })
    },
    async addNewService (service) {
      await axios
        .post(`${BeaconapiUrl}/api/service`, service)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(`Service ${result.data.serviceInitials} Successfully Added!`)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async deleteService (serviceId) {
      await axios
        .delete(`${BeaconapiUrl}/api/service/${serviceId}`)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch(error => {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            this.setDangerMessage(error.message)
          } else if (error.request) {
            // client never received a response, or request never left
            this.setDangerMessage(error.message)
          } else {
            // anything else
            this.setDangerMessage(error.message)
          }
        })
    },
    async activateServices (services) {
      await axios
        .put(`${BeaconapiUrl}/api/service/activate`, services)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.appendDangerMessage(error)
        })
    }
  },
  computed: {}
}
</script>
