import Vue from 'vue'

import { v4 as uuidv4 } from 'uuid'

export const filterEventBus = new Vue()

export const store = Vue.observable({
  isFilterMenuOpen: false,
  filters: []
})

export const filterTypes = {
  TEXT: 1, // Free text, default
  BOOL: 2, // True/False/Yes/No
  ACTIVESTATUS: 3, // Active/Inactive/All
  DATE: 4, // Date picker
  DUEDATE: 5 // Overdue/Upcoming/All
}

export const filterComparisonTypes = {
  EQUALS: 1,
  LIKE: 2,
  LESSTHAN: 3,
  GREATERTHAN: 4,
  LESSTHANOREQUAL: 5,
  GREATERTHANOREQUAL: 6
}

export const mutations = {
  toggleFilterMenu () {
    store.isFilterMenuOpen = !store.isFilterMenuOpen

    // Shift main page content to the left for filter sidebar
    document.getElementById('app-container-body').classList.value = ''
    store.isFilterMenuOpen ? document.getElementById('app-container-body').classList.add('main-shifted-left') : document.getElementById('app-container-body').classList.remove('main-shifted-left')
  },
  initializeFilters (tableFields) {
    store.filters = []
    // Ignore fields that do not have a dataType set
    tableFields.filter(f => f.filterType !== undefined)
      .forEach(function (field, i) {
        if (field.name.includes('active')) {
          store.filters.push({
            columnDisplayName: field.title,
            columnName: field.sortField,
            type: filterTypes.ACTIVESTATUS,
            search: [{
              filterSearchID: uuidv4(),
              value: -1,
              comparisonType: filterComparisonTypes.EQUALS,
              isDuplicate: false
            }]
          })
        } else if (field.filterType.includes('duedate')) {
          store.filters.push({
            columnDisplayName: field.title,
            columnName: field.sortField,
            type: filterTypes.DUEDATE,
            search: [{
              filterSearchID: uuidv4(),
              value: -1,
              comparisonType: filterComparisonTypes.LESSTHAN,
              isDuplicate: false
            }]
          })
        } else if (field.filterType.includes('bool')) {
          store.filters.push({
            columnDisplayName: field.title,
            columnName: field.sortField,
            type: filterTypes.BOOL,
            search: [{
              filterSearchID: uuidv4(),
              value: -1,
              comparisonType: filterComparisonTypes.EQUALS,
              isDuplicate: false
            }]
          })
        } else if (field.filterType.includes('date')) {
          store.filters.push({
            columnDisplayName: field.title,
            columnName: field.sortField,
            type: filterTypes.DATE,
            dataType: field.filterType,
            search: [{
              filterSearchID: uuidv4(),
              value: '',
              comparisonType: filterComparisonTypes.EQUALS,
              isDuplicate: false
            }]
          })
        } else {
          store.filters.push({
            columnDisplayName: field.title,
            columnName: field.sortField,
            type: filterTypes.TEXT,
            dataType: field.filterType,
            search: [{
              filterSearchID: uuidv4(),
              value: '',
              comparisonType: filterComparisonTypes.LIKE,
              isDuplicate: false
            }]
          })
        }
      })
  },
  setFilterByColumn (columnName, value) {
    var filter = store.filters.find(f => f.columnName === columnName)
    var emptyFilterSearches = filter.search.filter(s => {
      return (s.value === '' && filter.type === filterTypes.TEXT) ||
        (s.value === -1 && filter.type !== filterTypes.TEXT)
    })

    // If any empty filters already exist, prefill those first
    if (emptyFilterSearches.length > 0) {
      filter.search.find(s => s.filterSearchID === emptyFilterSearches[0].filterSearchID).value = value
    // If no empty filters exist then create a new filter for that column
    } else {
      filter.search.push({
        filterSearchID: uuidv4(),
        value: value,
        comparisonType: mutations.getDefaultComparisonTypeForFilterType(filter.type),
        isDuplicate: true
      })
    }
  },
  duplicateFilter (filter) {
    store.filters.find(f => f.columnName === filter.columnName).search.push({
      filterSearchID: uuidv4(),
      value: mutations.getDefaultSearchValueForFilterType(filter.type),
      comparisonType: mutations.getDefaultComparisonTypeForFilterType(filter.type),
      isDuplicate: true
    })
  },
  removeFilter (filter, filterSearch) {
    var filterSearchArray = store.filters.find(f => f.columnName === filter.columnName).search
    // If it is a duplicate search, just delete that search
    if (filterSearch.isDuplicate) {
      filterSearchArray.splice(filter.search.indexOf(filterSearch), 1)
    // If the user removes the main search but other searches exist
    // then remove the main search and switch the next search to main
    } else if (!filterSearch.isDuplicate && filterSearchArray.length > 1) {
      filterSearchArray.splice(filter.search.indexOf(filterSearch), 1)
      filterSearchArray[0].isDuplicate = false
    // If only the main search exists, clear out the search value
    } else {
      filterSearchArray.find(s => s.filterSearchID === filterSearch.filterSearchID).value = mutations.getDefaultSearchValueForFilterType(filter.type)
    }
  },
  clearFilters () {
    store.filters.forEach(function (filter, i) {
      filter.search = [{
        filterSearchID: uuidv4(),
        value: mutations.getDefaultSearchValueForFilterType(filter.type),
        comparisonType: mutations.getDefaultComparisonTypeForFilterType(filter.type),
        isDuplicate: false
      }]
    })
  },
  getCurrentFilters () {
    var currentFilters = []
    store.filters.forEach((filter, i) => {
      var filterSearches = []
      if (filter.type === filterTypes.ACTIVESTATUS || filter.type === filterTypes.DUEDATE || filter.type === filterTypes.BOOL) {
        filterSearches = filter.search.filter(s => {
          return s.value !== -1
        })
      } else if (filter.type === filterTypes.TEXT || filter.type === filterTypes.DATE) {
        filterSearches = filter.search.filter(s => {
          return s.value.trim() !== ''
        })
      }

      if (filterSearches.length > 0) {
        currentFilters.push(filter)
        currentFilters[currentFilters.length - 1].search = filterSearches
      }
    })

    return currentFilters
  },
  getApiFilters () {
    var apiFilters = []
    var currentFilters = mutations.getCurrentFilters()
    currentFilters.forEach(filter => {
      filter.search.forEach(filterSearch => {
        apiFilters.push({
          columnName: filter.columnName,
          type: filter.type,
          comparisonType: filterSearch.comparisonType,
          search: filterSearch.value
        })
      })
    })

    return apiFilters
  },
  addDuplicateUrlParamFilter (filter, columnName) {
    store.filters.find(f => f.columnName === columnName).search.push({
      filterSearchID: uuidv4(),
      value: filter.value,
      comparisonType: filter.comparisonType,
      isDuplicate: true
    })
  },
  setFilterKey (key) {
    store.filterKey = key
  },
  // Internal helper methods
  getDefaultComparisonTypeForFilterType (filterType) {
    switch (filterType) {
      case filterTypes.ACTIVESTATUS:
      case filterTypes.DATE:
      case filterTypes.BOOL:
        return filterComparisonTypes.EQUALS
      case filterTypes.DUEDATE:
        return filterComparisonTypes.LESSTHAN
      case filterTypes.TEXT:
      default:
        return filterComparisonTypes.LIKE
    }
  },
  getDefaultSearchValueForFilterType (filterType) {
    switch (filterType) {
      case filterTypes.ACTIVESTATUS:
      case filterTypes.DUEDATE:
      case filterTypes.BOOL:
        return -1
      case filterTypes.TEXT:
      case filterTypes.DATE:
      default:
        return ''
    }
  },
  getComparisonTypeString (comparisonType) {
    switch (comparisonType) {
      case filterComparisonTypes.EQUALS:
        return 'Equals'
      case filterComparisonTypes.LIKE:
        return 'Contains'
      case filterComparisonTypes.LESSTHAN:
        return 'Less Than'
      case filterComparisonTypes.GREATERTHAN:
        return 'Greater Than'
      case filterComparisonTypes.LESSTHANOREQUAL:
        return 'Less Than Or Equal'
      case filterComparisonTypes.GREATERTHANOREQUAL:
        return 'Greater Than Or Equal'
      default:
        return 'error'
    }
  },
  getComparisonTypeOperator (comparisonType) {
    switch (comparisonType) {
      case filterComparisonTypes.EQUALS:
        return '='
      case filterComparisonTypes.LIKE:
        return 'contains'
      case filterComparisonTypes.LESSTHAN:
        return '<'
      case filterComparisonTypes.GREATERTHAN:
        return '>'
      case filterComparisonTypes.LESSTHANOREQUAL:
        return '≤'
      case filterComparisonTypes.GREATERTHANOREQUAL:
        return '≥'
      default:
        return 'error'
    }
  }
}
