<template>
  <div>
    <confirmation ref="Confirmation"></confirmation>
    <datatable-heading
      :title="$t('menu.services')"
      :changePageSize="changePageSize"
      :searchChange="_.debounce(searchChange, 500)"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :tableFields="fields"
      @filterChange="filterChange">
      <template v-slot:button>
        <b-button
            v-b-modal.modalright
            variant="primary"
            size="lg"
            :to="{ name: 'addservice' }"
          >Add New</b-button>
      </template>
    </datatable-heading>

    <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="serviceID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded"
            >
            <template slot="active" slot-scope="props">
                <div style="display:flex;justify-content:center;align-items;center;">
                  <ToggleSwitch
                    v-on:input="flipActive(props.rowData)"
                    v-model="props.rowData.active"
                    :emitOnMount="false"
                    theme="custom"
                    class="vue-switcher-small"
                    color="primary"
                    :id="props.rowData.serviceInitials"
                    v-b-tooltip.hover.left :title="`Activate/Deactivate ${props.rowData.serviceInitials}`"
                  />
                </div>
              </template>
            </vuetable>
          </b-card>
          <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />
        </b-colxx>
      </b-row>
    </b-overlay>

    <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="onContextMenuAction('edit')">
        <i class="simple-icon-docs" />
        <span>Edit</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('activate')">
        <i class="simple-icon-trash" />
        <span>Activate</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('deactivate')">
        <i class="simple-icon-trash" />
        <span>Deactivate</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>

<script>
import confirmation from '../../../../components/Common/Confirmation.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../../components/Common/VuetablePaginationBootstrap'
import switches from 'vue-switches'
import { BeaconapiUrl } from '../../../../constants/config'

import DatatableHeading from '../../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../../constants/VuetableBootstrapconfig'
import ServiceMixin from '../../../../mixins/ServiceMixin.vue'

// so we can set our messages in the layout if needed
import { mapMutations } from 'vuex'

export default {
  props: ['title'],
  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation: confirmation,
    ToggleSwitch: switches
  },
  mixins: [ServiceMixin],
  data () {
    // because the id field is used in other places throughout this page here it should be
    // identified as a variable so that we don't have it hardcoded everywhere
    var _idfield = 'serviceID'
    return {
      isLoad: false,
      apiBase: BeaconapiUrl + '/api/service/table',
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      filters: [],
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: '__checkbox',
          title: '',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right selected',
          width: '5%'
        },
        {
          name: _idfield,
          sortField: _idfield,
          title: 'Service ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'number'
        },
        {
          name: 'serviceInitials',
          sortField: 'serviceInitials',
          title: 'Service Initials',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'text'
        },
        {
          name: 'serviceDescription',
          sortField: 'serviceDescription',
          title: 'Service Description',
          titleClass: '',
          dataClass: 'text-muted',
          width: '40%',
          filterType: 'text'
        },
        {
          name: 'programInitials',
          sortField: 'programInitials',
          title: 'Program Initials',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'text'
        },
        {
          name: '__slot:active',
          sortField: 'active',
          title: 'Active',
          titleClass: 'center aligned text-center',
          dataClass: 'center aligned text-center',
          width: '10%',
          filterType: 'active'
        }
      ]
    }
  },

  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage']),

    // Vue Table plumbing methods (standardized)

    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    getIndex (value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i
        }
      }
      return -1
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    rowClicked (dataItem, event) {
      const itemId = dataItem[this.$data.idfield]
      if (this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(x => x !== itemId)
      } else {
        this.$refs.vuetable.selectedTo.push(itemId)
      }
      this.selectedItems = this.$refs.vuetable.selectedTo
    },

    // End of Standard Vue Table methods

    // Component-specific Vue Table methods

    onContextMenuAction (action) {
      var rightClickedItem = this.rightClickedItem
      var matchedItems = this.$refs.vuetable.tableData.filter(item => this.selectedItems.includes(item.serviceID))
      var serviceNames = matchedItems.map(service => service.serviceInitials).join(', ')
      switch (action) {
        case 'activate':
        {
          this.$refs.Confirmation.show({
            title: 'Activate Services',
            message: `Are you sure you want to activate the following services: ${serviceNames}?`,
            okButton: 'Activate'
          }).then((result) => {
            if (result) {
              this.activateItems(true, matchedItems)
            }
          })
          break
        }
        case 'deactivate':
        {
          this.$refs.Confirmation.show({
            title: 'Deactivate Services',
            message: `Are you sure you want to deactivate the following services: ${serviceNames}?`,
            okButton: 'Deactivate'
          }).then((result) => {
            if (result) {
              this.activateItems(false, matchedItems)
            }
          })
          break
        }
        case 'edit':
        {
          if (this.selectedItems.length > 1) {
            this.$refs.Confirmation.show({
              title: 'Error',
              message: 'You may only edit one item at a time',
              okButton: 'Cancel',
              exitFunction: (e) => {
                if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
                  this.refreshVueTable()
                }
              }
            })
          } else {
            this.$router.push({ name: 'editservice', params: { serviceID: this.selectedItems[0] } })
          }
          break
        }
        default:
          break
      }
    },

    // End of Component-specific Vue Table methods

    // Component-specific methods

    flipActive (service) {
      this.$refs.Confirmation.show({
        title: service.active ? 'Activate Service' : 'Deactivate Service',
        message: 'Are you sure you want to ' + (service.active ? 'activate' : 'deactivate') + ` ${service.serviceDescription}?`,
        okButton: 'Yes',
        exitFunction: (e) => {
          if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
            this.refreshVueTable()
          }
        }
      }).then(async (result) => {
        if (result) {
          this.setInfoMessage('')
          await this.updateService(service)
            .then(response => {
              this.refreshVueTable()
            })
        } else {
          this.refreshVueTable()
        }
      })
    },
    async activateItems (activateBool, matchedItems) {
      var activateServicesList = matchedItems.map(service => ({ ID: service.serviceID, Active: activateBool }))
      await this.activateServices(activateServicesList)
        .then(response => {
          this.refreshVueTable()
        })
    }

    // End of Component-specific methods
  },
  computed: {
    isTrashVisible () {
      return this.selectedItems.length > 0
    },
    activeattr (serviceactive) {
      if (serviceactive) {
        return 'outline-success'
      } else {
        return 'outline-danger'
      }
    }
  }
}
</script>
