<template>
  <b-row>
    <b-colxx xxs="12">
      <div class="datatable-heading-container">
        <div style="flex-grow:1;" v-if="titleSectionVisible">
          <h1>{{ title }}</h1>
        </div>

        <div :class="topSlotClass">
          <slot name="button"></slot>
        </div>
      </div>
      <div class="mt-2">
        <b-button
          variant="empty"
          class="pt-0 pl-0 d-inline-block d-md-none"
          v-b-toggle.displayOptions>
          {{ $t('pages.display-options') }}
          <i class="simple-icon-arrow-down align-middle" />
        </b-button>
        <b-collapse id="displayOptions" class="d-md-block mb-3">
          <div class="d-block d-md-inline-block pt-1">
            <div class="search-sm d-inline-block float-md-left mr-1 align-top" v-if="hasSearch !== false">
              <b-input :placeholder="$t('menu.search')"  @input="(val) => searchChange(val)" />
            </div>
            <div class="float-md-left pt-1">
              <slot></slot>
            </div>
            </div>
          <div class="float-md-right pt-1">
            <span class="text-muted text-small mr-1 mb-2">{{from}}-{{to}} of {{ total }}</span>
            <div class="d-inline-block" v-if="pageSizeDropdownVisible">
              <b-dropdown
                id="ddown2"
                right
                :text="`${perPage}`"
                variant="outline-dark"
                class="d-inline-block"
                size="xs">
                <b-dropdown-item
                  v-for="(size,index) in pageSizes"
                  :key="index"
                  @click="changePageSize(size)">
                  {{ size }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </b-collapse>
      </div>
      <div class="separator mb-3" />
      <div class="filter-list-and-button float-md-right pt-1 mb-3" v-if="filterVisible">
        <div v-for="filter in currentFilters" :key="`${filter.columnName}-column-wrapper`" class="sub-filter-list">
          <div v-for="filterSearch in filter.search" :key="`${filterSearch.filterSearchID}-tag-wrapper`">
            <selected-item-tag :itemID="filterSearch.filterSearchID" @remove-clicked="removeFilter(filter, filterSearch)">
              <template v-slot:tag-label>
                {{ getFilterSearchDisplay(filter, filterSearch) }}
              </template>
            </selected-item-tag>
          </div>
        </div>

        <span class="filter-by-button ml-3" @click="toggleFilterMenu">
          <i class="iconsminds-filter-2"></i> {{ $t("datatable.filter-by") }}
        </span>
      </div>
    </b-colxx>
  </b-row>
</template>
<script>
import { store, mutations, filterEventBus, filterTypes } from '../../store/FilterMenu'

import SelectedItemTag from '../../components/Form/SelectedItemTag.vue'

export default {
  created () {
    if (this.filterVisible) {
      filterEventBus.$on('filterChange', () => {
        this.fireFilterEvent()
      })

      mutations.initializeFilters(this.tableFields)

      var hasUrlParams = false
      const urlParams = new URL(location.href).searchParams

      store.filters.forEach((filter, i) => {
        var param = urlParams.get(filter.columnName)
        if (param) {
          hasUrlParams = true

          var paramFilters = JSON.parse(param.toString())

          paramFilters.forEach(f => {
            if (f.isDuplicate) {
              mutations.addDuplicateUrlParamFilter(f, filter.columnName)
            } else {
              var baseFilter = filter.search.find(s => !s.isDuplicate)
              baseFilter.value = f.value
              baseFilter.comparisonType = f.comparisonType
            }
          })
        }
      })

      if (hasUrlParams) {
        this.fireFilterEvent(false)
      }
    }
  },
  beforeRouteLeave () {
    filterEventBus.$off('filterChange')
    mutations.resetStore()
  },
  props: [
    'title',
    'changePageSize',
    'searchChange',
    'from',
    'to',
    'total',
    'perPage',
    'hasPageSizes',
    'hasTitleSection',
    'tableFields',
    'titleAndButton',
    'hasSearch'
  ],
  components: {
    'selected-item-tag': SelectedItemTag
  },
  data () {
    return {
      pageSizes: [10, 50, 200, 500],
      pageSizeDropdownVisible: this.hasPageSizes === undefined ? true : this.hasPageSizes,
      titleSectionVisible: this.hasTitleSection === undefined ? true : this.hasTitleSection,
      filterVisible: this.tableFields === undefined ? false : this.tableFields.length > 0,
      currentFilters: []
    }
  },
  methods: {
    toggleFilterMenu () {
      mutations.toggleFilterMenu()
    },
    removeFilter (filter, filterSearch) {
      mutations.removeFilter(filter, filterSearch)
      this.fireFilterEvent()
    },
    fireFilterEvent (changeUrl = true) {
      this.currentFilters = this.createCopyOfFilters()

      if (changeUrl) {
        const urlParams = new URLSearchParams()
        this.currentFilters.forEach(filter => {
          var filterSearches = filter.search.map(s => {
            return {
              value: s.value,
              isDuplicate: s.isDuplicate,
              comparisonType: s.comparisonType
            }
          })
          urlParams.set(filter.columnName, JSON.stringify(filterSearches))
        })

        history.replaceState(null, null, '?' + urlParams.toString())
      }

      var apiFilters = mutations.getApiFilters()

      this.$emit('filterChange', apiFilters)
    },
    createCopyOfFilters () {
      // Create a copy of the selected filters list so that it does not change in the future when the user
      // makes changes in the filter menu
      var copiedFilterList = JSON.stringify(mutations.getCurrentFilters())
      return JSON.parse(copiedFilterList)
    },
    getFilterSearchDisplay (filter, filterSearch) {
      if (filter.type === filterTypes.ACTIVESTATUS) {
        return filterSearch.value === 1 ? 'Active' : 'Inactive'
      } else if (filter.type === filterTypes.DUEDATE) {
        return filterSearch.value === 1 ? 'Overdue' : 'Upcoming'
      } else if (filter.type === filterTypes.BOOL) {
        return filterSearch.value === 1 ? `${filter.columnDisplayName} - Yes` : `${filter.columnDisplayName} - No`
      } else if (filter.type === filterTypes.TEXT || filter.type === filterTypes.DATE) {
        return `${filter.columnDisplayName} ${mutations.getComparisonTypeOperator(filterSearch.comparisonType)} ${filterSearch.value}`
      }
    }
  },
  computed: {
    topSlotClass: {
      get: function () {
        if (this.titleAndButton) {
          return 'full-width'
        } else {
          return 'datatable-top-right-button-container'
        }
      }
    }
  }
}
</script>
